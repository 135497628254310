const dev = {
  s3: {
    REGION: "us-east-1",
    BUCKET: "notes-app-2-api-dev-attachmentsbucket-1ohphpm2dihn0"
  },
  apiGateway: {
    REGION: "us-east-1",
    URL: "https://sepdl9ptfc.execute-api.us-east-1.amazonaws.com/dev"
  },
  cognito: {
    REGION: "us-east-1",
    USER_POOL_ID: "us-east-1_otaWYEdLR",
    APP_CLIENT_ID: "6s7av1mcfvstnsuq45ad5nn7nd",
    IDENTITY_POOL_ID: "us-east-1:6182a24c-139c-4ac9-9886-263ecbc7769d"
  },
  STRIPE_KEY: "pk_test_q10MW0kcfPstDnqZacuFs1B400CrglyWVI",

};

const prod = {
  s3: {
    REGION: "us-east-1",
    BUCKET: "notes-app-2-api-prod-attachmentsbucket-z75vf9lnvq8t"
  },
  apiGateway: {
    REGION: "us-east-1",
    URL: "https://gcp064xdn3.execute-api.us-east-1.amazonaws.com/prod"
  },
  cognito: {
    REGION: "us-east-1",
    USER_POOL_ID: "us-east-1_UTg3NbSlH",
    APP_CLIENT_ID: "7k78t1529jq9psm41crgjaf275",
    IDENTITY_POOL_ID: "us-east-1:6c0aca87-4d0b-432e-9642-5f6f295dae9c"
  },
  STRIPE_KEY: "pk_test_q10MW0kcfPstDnqZacuFs1B400CrglyWVI",

};

// Default to dev if not set
const config = process.env.REACT_APP_STAGE === 'prod'
  ? prod
  : dev;

export default {
  // Add common config values here
  MAX_ATTACHMENT_SIZE: 5000000,
  ...config
};
